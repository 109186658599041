import React, { Component } from 'react';
import css from './PaymentProcessingOverlay.scss';

class PaymentProcessingOverlay extends Component {
  componentDidMount() {
    document.body.classList.add('modal-open');
  }

  componentWillUnmount() {
    document.body.classList.remove('modal-open');
  }

  render() {
    const { children } = this.props;

    return (
      <div className={css['loading-overlay']}>
        <div className={css['loading-dialog']}>
          <div className={css['spinner-wrap']}>
            <i className="fa fa-spinner fa-spin" aria-hidden="true" />
          </div>
          {children}
        </div>
      </div>
    );
  }
}

export default PaymentProcessingOverlay;
