import React from 'react';
import css from './InlinePaymentFormHeader.scss';
import img from './pic_secure.png';

function InlinePaymentFormHeader() {
  return <div className={css.wrap}>
    <div className={css.left}>
      <div className={css.title}>
        <i className="fal fa-lock-alt" />
        <span>Payment information</span>
      </div>
      <div className={css.subtitle}>Your payment information is secure.</div>
    </div>
    <div className={css.right}>
      <img src={img} alt="PCI secure" />
    </div>
  </div>
}

export default InlinePaymentFormHeader;
