// @flow

import React, { Component } from 'react';
import { Portal } from 'react-portal';
import { observer } from 'mobx-react';
import css from './ModalError.scss';

type ErrorType = {
  message: string
};

type Props = {
  closeError: (error: ErrorType) => void,
  error: ErrorType,
  withActionOk: boolean,
  zIndex?: number
};

export class ModalError extends Component<Props> {
  static defaultProps = {
    zIndex: 1000
  };

  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose(e) {
    e.stopPropagation();
    e.preventDefault();
    this.props.closeError(this.props.error);
  }

  render() {
    const { error, withActionOk, zIndex } = this.props;
    return (
      <Portal>
        <div // eslint-disable-line jsx-a11y/no-static-element-interactions
          className={css.overlay}
          onClick={this.handleClose}
          style={{ zIndex }}
        >
          <div className={css.modal}>
            <a
              className={css.close}
              onClick={this.handleClose}
              role="button"
              tabIndex={0}
            >
              &#x2715;
            </a>
            <div className={css.inner}>
              <div dangerouslySetInnerHTML={{ __html: error.message }} />
              {withActionOk && (
                <div className={css.actions}>
                  <button
                    className="ss-btn ss-btn-primary"
                    onClick={this.handleClose}
                  >
                    OK
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </Portal>
    );
  }
}

export default observer(ModalError);
