import request from 'axios';
import qs from 'qs';

export const getAuthToken = () => $('meta[name=csrf-token]').attr('content');

export default new (class Api {
  getAdvice = params =>
    request({
      method: 'GET',
      url: `/advice.json`,
      params
    });

  deleteComment = params =>
    request({
      method: 'DELETE',
      url: `/comments/${params.id}.json`,
      data: {
        authenticity_token: getAuthToken()
      }
    });

  deleteAdvice = params =>
    request({
      method: 'DELETE',
      url: `/advice/${params.id}.json`,
      data: {
        authenticity_token: getAuthToken()
      }
    });

  updateComment = params =>
    request({
      method: 'PUT',
      url: `/comments/${params.id}.json`,
      data: {
        comment: {
          ...params,
          id: null
        },
        authenticity_token: getAuthToken()
      }
    });

  updateAdvice = params =>
    request({
      method: 'PUT',
      url: `/advice/${params.id}.json`,
      data: {
        advice: {
          ...params,
          id: null
        },
        authenticity_token: getAuthToken()
      }
    });

  createComment = params =>
    request({
      method: 'POST',
      url: '/comments.json',
      data: {
        comment: {
          ...params
        },
        authenticity_token: getAuthToken()
      }
    });

  createAdvice = params =>
    request({
      method: 'POST',
      url: '/advice.json',
      data: {
        advice: {
          ...params
        },
        authenticity_token: getAuthToken()
      }
    });

  getReplies = params =>
    request({
      method: 'GET',
      url: '/comments.json',
      params
    });

  like = params =>
    request({
      method: 'POST',
      url: '/helpful_votes/toggle',
      data: {
        ...params,
        authenticity_token: getAuthToken()
      }
    });

  whoVoted = params =>
    request({
      method: 'GET',
      url: '/helpful_votes/who_voted',
      paramsSerializer(p) {
        return qs.stringify(p, { indices: false });
      },
      params: { helpful_vote: params, user_action_name: 'like' }
    });

  abuse = params =>
    request({
      method: 'POST',
      url: '/abuse_reports',
      paramsSerializer(p) {
        return qs.stringify(p, { indices: false });
      },
      data: {
        abuse_report: params,
        authenticity_token: getAuthToken()
      }
    });

  searchAdvice = params =>
    request({
      method: 'GET',
      url: '/advice/search.json',
      paramsSerializer(p) {
        return qs.stringify(p, { indices: false });
      },
      params: {
        q: params
      }
    });

  trackUnfinishedForm = ({ email, source, meta }) => {
    request({
      url: '/unfinished_forms',
      method: 'POST',
      data: {
        email,
        source,
        meta,
        authenticity_token: getAuthToken()
      }
    });
  };

  login = ({ email, password, url }) =>
    request({
      url,
      method: 'POST',
      data: {
        user: {
          email,
          password
        },
        authenticity_token: getAuthToken()
      }
    });

  validateOrderParams = data =>
    request({
      url: '/teapi/orders/validate',
      method: 'POST',
      data: {
        ...data,
        authenticity_token: getAuthToken()
      }
    });

  registerWithPromoTicket = ({ promotionSlug, ticketId, params }) =>
    request({
      url: `/promotions/${promotionSlug}/promo_tickets/${ticketId}/sign_up`,
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      data: {
        promo_ticket: {
          ...params
        },
        authenticity_token: getAuthToken()
      }
    });

  getPerformances = promotionId =>
    request({
      url: `/promotions/${promotionId}.json`,
      method: 'GET'
    });

  promoTicketCheckout = ({ promotionId, params }) => request({
      url: `/promotions/${promotionId}/checkout.json`,
      method: 'POST',
      data: {
        promo_ticket: {
          ...params
        },
        authenticity_token: getAuthToken()
      }
    });

  joinOrLeaveWaitlist = ({ promotionId, performanceId }) => request({
      url: `/promotions/${promotionId}/join_or_leave_waitlist.json`,
      method: 'POST',
      data: {
        promo_ticket: {
          performance_id: performanceId
        },
        authenticity_token: getAuthToken()
      }
    });

  noneOfTheseDates = ({ promotionId }) => request({
      url: `/promotions/${promotionId}/none_of_these_dates.json`,
      method: 'POST',
      data: {
        authenticity_token: getAuthToken()
      }
    });

  getAuthToken = () => $('meta[name=csrf-token]').attr('content');
})();
