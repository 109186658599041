// @flow

import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import ScriptPreloader from 'shared/components/ScriptPreloader';
import StripePaymentModal from './StripePaymentModal';
import StripePaymentInline from './StripePaymentInline';
import InlinePaymentFormHeader from './InlinePaymentFormHeader';

type StripeRemoteErrorType = {
  code: string,
  message: string,
  type: string
};

type Props = {
  user: {
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string
  },
  order: {
    priceCents: number,
    currency: string
  },
  onTokenCreated: (result: {
    token?: string,
    error: StripeRemoteErrorType
  }) => void, // callback for Stripe to send token outside of component
  onCancel?: () => void, // when stripe modal closed
  useInline: boolean, // which Stripe solution should be used and rendered
  canSubmit: boolean, // should payment button be enabled or disabled
  submitButtonText: string,
  headerComponent: React.ElementType,
  beforeSubmitButtonComponent: React.ElementType
};

class Payment extends Component<Props> {
  static defaultProps = {
    onCancel: () => {}
  };

  @observable isStripeLoaded = false;
  @observable isStripeCheckoutLoaded = false;

  setIsStripeLoaded = () => {
    this.isStripeLoaded = true;
  };

  setIsStripeCheckoutLoaded = () => {
    this.isStripeCheckoutLoaded = true;
  };

  render() {
    const {
      user,
      order,
      onTokenCreated,
      onCancel,
      useInline,
      canSubmit,
      submitButtonText,
      headerComponent,
      beforeSubmitButtonComponent
    } = this.props;

    return (
      <div>
        {headerComponent && headerComponent}
        {!headerComponent && useInline && <InlinePaymentFormHeader />}
        {useInline ? (
          <StripePaymentInline
            {...{
              canSubmit,
              submitButtonText,
              onTokenCreated,
              beforeSubmitButtonComponent,
              isStripeLoaded: this.isStripeLoaded
            }}
          />
        ) : (
          <StripePaymentModal
            {...{
              user,
              order,
              onCancel,
              canSubmit,
              submitButtonText,
              onTokenCreated,
              isStripeCheckoutLoaded: this.isStripeCheckoutLoaded
            }}
          />
        )}

        {useInline || (
          <ScriptPreloader
            url="https://checkout.stripe.com/checkout.js"
            onLoad={this.setIsStripeCheckoutLoaded}
          />
        )}

        {useInline && (
          <ScriptPreloader
            url="https://js.stripe.com/v3/"
            onLoad={this.setIsStripeLoaded}
          />
        )}
      </div>
    );
  }
}

export default observer(Payment);
