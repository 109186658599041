// @flow

import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import cn from 'classnames';
import css from './PhoneNumberField.scss';
import PhoneNumberInput from './PhoneNumberInput'

type Props = {
  onChange?: (value: string) => void,
  value?: string,
  errors?: Array<{ field: string, message: string }>,
  onValidate?: (value: string) => void,
  inputName: string,
  optionalInputClassName: string,
  isShowFormValidationErrors: boolean,
  registerPhoneValidator: (value: Function) => void,
  optionalTelInputClassname?: string
};

class PhoneNumberField extends Component<Props> {
  static defaultProps = {
    onChange() {},
    value: null,
    errors: [],
    optionalTelInputClassname: null,
    onValidate() {}
  };

  @observable phoneInputValue: string;
  @observable realPhoneNumber: string;

  constructor(props) {
    super(props);
    this.phoneInputValue = this.props.value;
    this.realPhoneNumber = this.props.value;
    this.phoneNumberFieldRef = React.createRef();
  }

  handleGetNumber = (number: string) => {
    this.props.onChange(number);
    this.realPhoneNumber = number;
  };

  handlePhoneInputChange = (value: string) => {
    this.phoneInputValue = value;
  };

  render() {
    const {
      label = 'phone',
      inputName,
      optionalInputClassName,
      isShowFormValidationErrors,
      registerPhoneValidator,
      onValidate,
      errors,
      optionalTelInputClassname
    } = this.props;

    const hasError = errors.length > 0 && isShowFormValidationErrors;

    return (
      <div
        className={cn(css['form-field'], 'form-group', {
          'has-error': hasError
        })}
      >
        {label && <label className="control-label">{label}</label>}
        <PhoneNumberInput
          onGetNumber={this.handleGetNumber}
          value={this.phoneInputValue}
          onChange={this.handlePhoneInputChange}
          ref={this.phoneNumberFieldRef}
          registerPhoneValidator={registerPhoneValidator}
          optionalInputClassName={optionalTelInputClassname}
          onValidate={onValidate}
        />
        {inputName && (
          <input
            className={optionalInputClassName}
            type="hidden"
            name={inputName}
            value={this.realPhoneNumber}
          />
        )}
        {hasError && (
          <div className={css['error-message']}>
            <div>{errors[0].message}</div>
          </div>
        )}
      </div>
    );
  }
}
export default observer(PhoneNumberField);
