// @flow

import { Component } from 'react';

type Props = {
  attributes?: {},
  onCreate?: () => void,
  onError?: () => void,
  onLoad?: () => void,
  url: string
};

class ScriptPreloader extends Component<Props> {
  static scriptObservers = {};

  static loadedScripts = {};

  static erroredScripts = {};

  static idCount = 0;

  constructor(props: Props) {
    super(props);
    this.scriptLoaderId = `id${this.constructor.idCount++}`; // eslint-disable-line space-unary-ops, no-plusplus
  }

  componentDidMount() {
    const { onError, onLoad, url } = this.props;

    if (this.constructor.loadedScripts[url]) {
      onLoad();
      return;
    }

    if (this.constructor.erroredScripts[url]) {
      onError();
      return;
    }

    if (this.constructor.scriptObservers[url]) {
      this.constructor.scriptObservers[url][this.scriptLoaderId] = this.props;
      return;
    }

    this.constructor.scriptObservers[url] = {
      [this.scriptLoaderId]: this.props
    };

    this.createScript();
  }

  componentWillUnmount() {
    const { url } = this.props;
    const observers = this.constructor.scriptObservers[url];

    // If the component is waiting for the script to load, remove the
    // component from the script's observers before unmounting the component.
    if (observers) {
      delete observers[this.scriptLoaderId];
    }
  }

  createScript() {
    const { onCreate, url, attributes } = this.props;
    const script = document.createElement('script');
    script.id = 'stripe-js';

    onCreate();

    // add 'data-' or non standard attributes to the script tag
    if (attributes) {
      Object.keys(attributes).forEach(prop =>
        script.setAttribute(prop, attributes[prop])
      );
    }

    script.src = url;

    // default async to true if not set with custom attributes
    if (!script.hasAttribute('async')) {
      script.async = 1;
    }

    const callObserverFuncAndRemoveObserver = shouldRemoveObserver => {
      const observers = this.constructor.scriptObservers[url];
      Object.keys(observers).forEach(key => {
        if (shouldRemoveObserver(observers[key])) {
          delete this.constructor.scriptObservers[url][this.scriptLoaderId];
        }
      });
    };
    script.onload = () => {
      this.constructor.loadedScripts[url] = true;
      callObserverFuncAndRemoveObserver(observer => {
        observer.onLoad();
        return true;
      });
    };

    script.onerror = () => {
      this.constructor.erroredScripts[url] = true;
      callObserverFuncAndRemoveObserver(observer => {
        observer.onError();
        return true;
      });
    };

    document.body.appendChild(script);
  }

  render() {
    return null;
  }
}

ScriptPreloader.defaultProps = {
  attributes: {},
  onCreate: () => {},
  onError: () => {},
  onLoad: () => {}
};

export default ScriptPreloader;
