import React from 'react';
import { observer } from 'mobx-react';
import css from './KnownUserWidget.scss';

function KnownUserWidget({ knownUser, onNotYouClick }) {
  return <div className={css.wrap}>
    <div className={css.avatar}>
      {knownUser.avatar ? (
        <img
          className="img-responsive"
          src={knownUser.avatar}
          alt={knownUser.displayName}
        />
      ) : (
        knownUser.displayName[0].toUpperCase()
      )}
    </div>
    <div className={css.info}>
      <p className="fw-700">{knownUser.displayName}</p>
      <p>{knownUser.email}</p>
      <p>
        <a role="button" tabIndex={-1} onClick={onNotYouClick}>
          Not you?
        </a>
      </p>
    </div>
  </div>
}

export default observer(KnownUserWidget);
