// @flow

import React, { Component } from 'react';
import { observer } from 'mobx-react';
import ShowScoreIcon from 'images/apple-touch-icon.png';

type Props = {
  onTokenCreated: (token: string) => void,
  user: {
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string
  },
  order: {
    priceCents: number
  },
  onCancel: () => void,
  canSubmit: boolean,
  submitButtonText: string,
  isStripeCheckoutLoaded: boolean
};

class StripePaymentModal extends Component<Props> {
  createToken = async e => {
    const { canSubmit } = this.props;

    if (!canSubmit) return;

    e.preventDefault();
    e.stopPropagation();

    const { onTokenCreated, onCancel, user, order } = this.props;

    const stripeCheckoutConfig = {
      name: 'Show-Score',
      key: SS.stripeKey,
      image: ShowScoreIcon,
      closed: () => onCancel(),
      token: async result => {
        onTokenCreated(result.id);
      }
    };

    const orderDetails = {
      email: user.email,
      amount: order.priceCents,
      currency: order.currency
    };

    const stripeHandler = StripeCheckout.configure(stripeCheckoutConfig); // eslint-disable-line no-undef

    stripeHandler.open(orderDetails);
  };

  render() {
    const { submitButtonText, canSubmit, isStripeCheckoutLoaded } = this.props;

    if (!isStripeCheckoutLoaded) return null;

    return (
      <button
        type="button"
        className="ss-btn ss-btn-green ss-btn-lg btn-block"
        onClick={this.createToken}
        disabled={!canSubmit}
      >
        {submitButtonText}
      </button>
    );
  }
}

export default observer(StripePaymentModal);
