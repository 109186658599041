export default function log(...args) {
  if (!window.console || !window.console.log) return;

  if (
    window.debug ||
    process.env.RAILS_ENV === 'development' ||
    process.env.RAILS_ENV === 'staging'
  ) {
    args.unshift('S-S Logger');
    console.log(...args); // eslint-disable-line no-console
  }

  if (window.Sentry) {
    window.Sentry.captureException(...args);
  }
}
