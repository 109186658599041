// @flow

import React from 'react';
import { observer } from 'mobx-react';
import { StripeProvider, Elements } from 'react-stripe-elements';
import StripeElementsContainer from './StripeElementsContainer';

type Props = {
  submitButtonText: string,
  onTokenCreated: (token: string) => void,
  beforeSubmitButtonComponent: React.ElementType,
  isStripeLoaded: boolean
};

function StripePaymentInline({
  onTokenCreated,
  submitButtonText,
  beforeSubmitButtonComponent,
  isStripeLoaded,
  canSubmit
}: Props) {
  if (!isStripeLoaded) return null;

  const stripe = window.Stripe(SS.stripeKey);

  return (
    <StripeProvider stripe={stripe}>
      <Elements fonts={[{ cssSrc: 'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap' }]}>
        <StripeElementsContainer
          {...{
            canSubmit,
            onTokenCreated,
            submitButtonText,
            beforeSubmitButtonComponent
          }}
        />
      </Elements>
    </StripeProvider>
  );
}

export default observer(StripePaymentInline);
