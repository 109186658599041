// @flow

import React, { Component } from 'react';
import {
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement
} from 'react-stripe-elements';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import cn from 'classnames';
import css from './StripeElementsContainer.scss';
import type { StripeRemoteErrorType } from './Payment';

const BASE_STRIPE_INPUT_STYLE = {
  base: {
    fontWeight: 300,
    fontFamily: 'Roboto, Arial, sans-serif',
    fontSize: '14px',
    lineHeight: '1.42857',
    color: '#333',
    fontStyle: 'normal',
    '::placeholder': {
      color: '#999',
      fontWeight: 300
    }
  }
};

type Props = {
  stripe: any,
  onTokenCreated: (token: string) => void,
  canSubmit: boolean
};

class StripeElementsContainer extends Component<Props> {
  @observable stripeError: ?StripeRemoteErrorType = null;

  createToken = async () => {
    this.stripeError = null;

    const { stripe, onTokenCreated, canSubmit } = this.props;

    if (!canSubmit) return;

    const result = await stripe.createToken();

    if (result.error) {
      this.stripeError = result.error;
      return;
    }

    if (result.token) {
      onTokenCreated(result.token.id);
    }
  };

  resetStripeError = () => {
    this.stripeError = null;
  };

  render() {
    const {
      canSubmit,
      submitButtonText,
      stripe,
      beforeSubmitButtonComponent
    } = this.props;

    if (!stripe) return null;

    const incompleteNumberError =
      this.stripeError && this.stripeError.code === 'incomplete_number';

    const incompleteExpiryError =
      this.stripeError && this.stripeError.code === 'incomplete_expiry';

    const incompleteCvcError =
      this.stripeError && this.stripeError.code === 'incomplete_cvc';

    const commonStripeError =
      this.stripeError &&
      !incompleteNumberError &&
      !incompleteExpiryError &&
      !incompleteCvcError;

    return (
      <div>
        {commonStripeError && (
          <div className="text-red">{this.stripeError.message}</div>
        )}
        <div className="row slim-gutters mb1">
          <div className="col-md-12">
            <div className={css['input-wrap']}>
              <span className={css['input-icon-wrap']}>
                <i className="fal fa-credit-card" />
              </span>
              <CardNumberElement
                onChange={this.resetStripeError}
                placeholder="Credit card"
                className={cn(css.input, {
                  [css['field-error']]: incompleteNumberError
                })}
                style={BASE_STRIPE_INPUT_STYLE}
              />
            </div>
            {incompleteNumberError && (
              <div className={css.error}>{this.stripeError.message}</div>
            )}
          </div>
        </div>
        <div className="row slim-gutters">
          <div className={cn('col-md-6', css['card-expiry-wrap'])}>
            <div className={css['input-wrap']}>
              <span className={css['input-icon-wrap']}>
                <i className="fal fa-calendar" />
              </span>
              <CardExpiryElement
                onChange={this.resetStripeError}
                className={cn(css.input, {
                  [css['field-error']]: incompleteExpiryError
                })}
                style={BASE_STRIPE_INPUT_STYLE}
              />
            </div>
            {incompleteExpiryError && (
              <div className={css.error}>{this.stripeError.message}</div>
            )}
          </div>
          <div className="col-md-6">
            <div className={css['input-wrap']}>
              <span className={css['input-icon-wrap']}>
                <i className="fal fa-lock-alt" />
              </span>
              <CardCVCElement
                onChange={this.resetStripeError}
                className={cn(css.input, {
                  [css['field-error']]: incompleteCvcError
                })}
                style={BASE_STRIPE_INPUT_STYLE}
              />
            </div>
            {incompleteCvcError && (
              <div className={css.error}>{this.stripeError.message}</div>
            )}
          </div>
        </div>

        {beforeSubmitButtonComponent && beforeSubmitButtonComponent}

        <div className="mt2">
          <button
            type="button"
            className="ss-btn ss-btn-green ss-btn-lg btn-block"
            onClick={this.createToken}
            disabled={!canSubmit || this.stripeError}
          >
            {submitButtonText}
          </button>
        </div>
      </div>
    );
  }
}

export default injectStripe(observer(StripeElementsContainer));
