import { Component } from 'react';

export default class ErrorBoundary extends Component {
  componentDidMount() {
    this.Sentry = window.Sentry;
  }

  componentDidCatch(error, info) {
    // For display fallback UI
    // Send error to Sentry
    if (this.Sentry) {
      this.Sentry.captureException(error, { extra: { info }});
    }
  }

  render() {
    return this.props.children;
  }
}
