// @flow

import { observable, action, computed } from 'mobx';
import log from 'shared/utils/log';
import * as EmailValidator from 'email-validator';
import api from 'shared/utils/api';
import type { IObservableArray } from 'mobx';

const EMPTY_FIELD_ERROR = `can't be blank`;
const INVALID_EMAIL_FIELD_ERROR = `not a valid email address format`;
const INVALID_PHONE_FIELD_ERROR = `not a valid phone number`;
const VALIDATABLE_USER_FIELDS = [
  'firstName',
  'lastName',
  'email',
  'phoneNumber'
];

interface RootStore {
  order: {
    creditCentsToApply: number
  };
  loginError: boolean | null;
  updateAuthToken: string => void;
  viewStore: {
    isShowFormValidationErrors: boolean,
    knownUser: {
      email: string
    }
  };
}

export type ValidationErrorType = {
  field: string,
  message: string
};

export type UserType = {
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string,
  hasPaidMembership: boolean,
  creditCents?: number
};

export default class UserStore {
  rootStore: RootStore;

  @observable
  user: UserType = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    hasPaidMembership: false,
    creditCents: 0
  };

  @observable isSignedIn: boolean = false;
  @observable.shallow
  privateValidationErrors: IObservableArray<ValidationErrorType> = [];

  phoneValidator: { validate: (value: string) => boolean };

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action.bound
  init({ user, isSignedIn }: { user: UserType, isSignedIn: boolean }) {
    if (user) {
      this.user = _.merge(
        this.user,
        _.mapKeys(user, (value, key) => _.camelCase(key))
      );
    }
    this.isSignedIn = isSignedIn;
  }

  @action.bound
  setUserField(fieldName: string, value: string) {
    this.user[fieldName] = value;
    if (this.rootStore.viewStore)
      this.rootStore.viewStore.isShowFormValidationErrors = true;
    this.clearFieldNameValidationError(fieldName);
  }

  @action.bound
  clearFieldNameValidationError(fieldName) {
    this.privateValidationErrors = this.privateValidationErrors.filter(err => err.field !== fieldName);
  }

  @action.bound
  setUser(userJson: UserType) {
    this.user = userJson;
  }

  @action.bound
  async login({
    email,
    password,
    url = '/teapi/sign_in',
    afterLoginCallback
  }: {
    email: string,
    password: string,
    url: string,
    afterLoginCallback: (data: any) => any
  }) {
    try {
      let emailToSubmit = email;

      if (emailToSubmit === '') {
        emailToSubmit = this.rootStore.knownUser.email;
      }

      const res = await api.login({ email: emailToSubmit, password, url });

      this.rootStore.loginError = null;

      const loginResult = res.data;

      if (loginResult.success) {
        this.setUser(loginResult.user);
        this.rootStore.updateAuthToken(loginResult.newAuthToken);
        this.isSignedIn = true;
      } else {
        this.rootStore.loginError = loginResult.message;
      }

      if (afterLoginCallback) {
        afterLoginCallback(loginResult);
      }

    } catch (err) {
      if (err.response) {
        this.rootStore.loginError = err.response.data.message;
      } else {
        log(err);
      }
    }
  }

  @action.bound
  addUserValidationError(field: string, message: string) {
    this.privateValidationErrors.push({
      field,
      message
    });
  }

  @action.bound
  addValidationErrorsFromResponse(errors: {
    [key: string]: Array<string>
  }): void {
    Object.keys(errors).forEach(field => {
      const normalizedField = this.normalizeUserField(field);
      errors[field].forEach(message => {
        this.addUserValidationError(normalizedField, message);
      });
    });
  }

  normalizeUserField = (field: string): string => {
    if (field === 'phone') {
      return 'phoneNumber';
    }

    return field;
  };

  @action.bound
  validateUserField(fieldName: string): boolean {
    if (!VALIDATABLE_USER_FIELDS.some(f => f === fieldName)) return true;

    if (_.isEmpty(this.user[fieldName])) {
      this.addUserValidationError(fieldName, EMPTY_FIELD_ERROR);
      return false;
    }

    if (fieldName === 'email') {
      if (!EmailValidator.validate(this.user.email)) {
        this.addUserValidationError(fieldName, INVALID_EMAIL_FIELD_ERROR);
        return false;
      }
    }

    if (
      fieldName === 'phoneNumber' &&
      this.phoneValidator &&
      !this.phoneValidator.validate(this.user.phoneNumber)
    ) {
      this.addUserValidationError(fieldName, INVALID_PHONE_FIELD_ERROR);
      return false;
    }

    return true;
  }

  @action.bound
  validateUser(): boolean {
    this.privateValidationErrors.clear();

    const results = VALIDATABLE_USER_FIELDS.map(fieldName => this.validateUserField(fieldName));

    const isValid = !results.some(r => r === false);

    return isValid;
  }

  @computed
  get remoteUserParams() {
    return {
      first_name: this.user.firstName,
      last_name: this.user.lastName,
      email: this.user.email,
      phone: this.user.phoneNumber
    };
  }

  @computed
  get userHasCredits(): boolean {
    if (this.user.creditCents && this.user.creditCents > 0) {
      return true;
    }

    return false;
  }

  @action.bound
  setUserCreditCents(value) {
    this.user.creditCents = value;
  }

  @action.bound
  async validateUserRemotely(): Promise<boolean> {
    try {
      const data = {
        user: this.remoteUserParams,
        select_id: this.rootStore.order.select.selectId
      };

      await api.validateOrderParams(data);
      return true;
    } catch (err) {
      if (err.response) {
        if (err.response.data.errors) {
          this.addValidationErrorsFromResponse(err.response.data.errors);
        } else {
          this.rootStore.errorsStore.createError(
            'modal',
            err.response.data.message,
            err.response.data.error_type
          );
        }
        return false;
      }
      log(err);
      return false;
    }
  }

  @action.bound
  async registerUserRemotely(promotionSlug, ticketId): Promise<boolean> {
    try {
      const data = {
        first_name: this.user.firstName,
        last_name: this.user.lastName,
        cell_phone: this.user.phoneNumber,
        email: this.user.email
      };

      await api.registerWithPromoTicket({
        promotionSlug,
        ticketId,
        params: data
      });

      document.dispatchEvent(new Event(SS.V2.Events.Navigation.REFRESH));

      return true;
    } catch (err) {
      if (err.response) {
        if (err.response.data.errors) {
          this.addValidationErrorsFromResponse(err.response.data.errors);
        } else {
          this.rootStore.errorsStore.createError(
            'modal',
            err.response.data.message,
            err.response.data.error_type
          );
        }
        return false;
      }
      return false;
    }
  }

  @computed
  get creditCentsAvailable(): number {
    if (!this.user.creditCents) return 0;

    return this.user.creditCents - this.rootStore.order.creditCentsToApply;
  }

  @computed
  get creditsAvailable(): string {
    return `${(parseInt(this.creditCentsAvailable, 10) / 100).toFixed(2)}`;
  }

  @computed
  get validationErrors(): Array<ValidationErrorType> {
    return _.uniqWith(this.privateValidationErrors.slice(), _.isEqual);
  }

  @computed
  get isUserValid(): boolean {
    return this.validationErrors.slice().length === 0;
  }

  registerPhoneValidator = phoneValidator => {
    this.phoneValidator = phoneValidator;
  };

  getFieldErrorsByFieldName = fieldName => this.validationErrors.filter(e => e.field === fieldName);
}
