// @flow

import React, { Component } from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';
import intlTelInput from 'intl-tel-input';
import type { SyntheticEvent } from 'react';
import 'intl-tel-input/build/css/intlTelInput.min.css';
import './PhoneNumberInput.scss';

type Props = {
  onChange: (value: string) => void,
  onValidate?: (value: string) => void,
  onGetNumber: (value: string) => void,
  inputName: string,
  optionalInputClassName: string,
  value: string
};

const buildInltTelInstance = ({ node, customPlaceholder }) =>
  intlTelInput(node, {
    initialCountry: 'us',
    customPlaceholder,
    preferredCountries: ['us', 'ca', 'gb'],
    separateDialCode: true,
    formatOnDisplay: true,
    utilsScript: require('intl-tel-input/build/js/utils.js') // eslint-disable-line global-require
  });

const customPlaceholder = (selectedCountryPlaceholder: string): string =>
  selectedCountryPlaceholder.replace(/[0-9]/g, '1');

class PhoneNumberInput extends Component<Props> {
  static defaultProps = {
    onValidate() {}
  };

  dom = {};
  intlTel;

  componentDidMount() {
    this.intlTel = buildInltTelInstance({
      node: this.dom.root,
      customPlaceholder
    });

    if (this.props.registerPhoneValidator) {
      this.props.registerPhoneValidator({
        validate: this.validateNumber
      });
    }

    const number = this.intlTel.getNumber();
    this.props.onGetNumber(number);
  }

  componentWillUnmount() {
    this.intlTel.destroy();
  }

  handleInputChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const number = this.intlTel.getNumber();
    this.props.onGetNumber(number);
    this.props.onChange(e.target.value);
  };

  validateNumber = () => this.intlTel.isValidNumber();

  render() {
    const { value, inputName, optionalInputClassName, onValidate } = this.props;

    let inputValue = value;

    if (!inputValue) {
      inputValue = '';
    }

    if (this.intlTel) this.intlTel.setNumber(inputValue);

    return (
      <input
        defaultValue={inputValue}
        onChange={this.handleInputChange}
        onBlur={() => {
          onValidate(inputValue);
        }}
        className={cn('form-control', optionalInputClassName)}
        name={inputName}
        ref={ref => {
          this.dom.root = ref;
        }}
      />
    );
  }
}

export default observer(PhoneNumberInput);
