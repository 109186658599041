import React, { Component } from 'react';

let BUTTON_ID;

if (process.env && process.env.NODE_ENV === 'test') {
  BUTTON_ID = 1;
} else {
  BUTTON_ID = `js-facebook-auth-button${1000 + _.random(9999)}`;
}

class FbLoginBtn extends Component {
  componentDidMount() {
    if (window.fbAsyncInit && typeof window.fbAsyncInit === 'function') {
      window.fbAsyncInit();
    }
  }

  render() {
    return (
      <div
        data-onlogin={`SS.V2.Users.FacebookAuth.checkLoginState('${BUTTON_ID}')`}
        className="fb-login-button"
        data-scope="public_profile,email"
        id={BUTTON_ID}
        data-size="large"
        data-button-type="continue_with"
        data-show-faces={false}
        data-auto-logout-link={false}
        data-redirect-url=""
        data-use-continue-as={false}
        data-max-rows={1}
      />
    );
  }
}

export default FbLoginBtn;
